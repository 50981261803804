import { useQuery } from "@tanstack/react-query";
import mandateService from "../../services/mandate.service";
import { useNavigate, useParams } from "react-router-dom";
import { TBody, Table, Td, Tr } from "../../layout/table";
import { timeAgo } from "../../sections/users/cards/calls-list";
import mandateEffectService from "../../services/mandate-effect.service";
import { useMandateMandateEffects } from "../../mandate-mandate-effects/hooks/use-mandate-mandate-effects";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useMemo } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";

function MandatePage({ darkMode }: { darkMode?: boolean }) {
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const { mandateId } = useParams();
    const mandatesQuery = useQuery(["mandate"], async () => {
        if (!mandateId) return;
        const result = await mandateService.getOne(mandateId);
        return result?.data;
    });
    const mandatesEffectQuery = useQuery(["mandateEffect"], async () => {
        const result = await mandateEffectService.getAll();
        return result?.data;
    });

    const mandateMandateEffectQuery = useMandateMandateEffects({
        authToken,
        filters: { ...(mandateId !== null ? { where: { mandateId: mandateId } } : {}) },
    });
    const mandateMandateEffects = useMemo(() => mandateMandateEffectQuery.data || [], [mandateMandateEffectQuery.data]);
    const { name, summary, createdAt, processedByAiAt, city, state, county } = mandatesQuery.data || {};
    const navigate = useNavigate();
    return mandatesQuery.data ? (
        <div className="flex flex-col">
            <div className="flex items-center gap-3 mb-4 cursor-pointer" onClick={() => navigate("/mandates")}>
                <ChevronLeftIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                <p>Mandates list</p>
            </div>
            <Table>
                <TBody>
                    <Tr>
                        <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>Mandate name</Td>
                        <Td>{name}</Td>
                    </Tr>
                    {createdAt && (
                        <Tr>
                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>Created at</Td>
                            <Td>{createdAt ? timeAgo(createdAt).toLocaleString() : null}</Td>
                        </Tr>
                    )}
                    {processedByAiAt && (
                        <Tr>
                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>Created at</Td>
                            <Td>{processedByAiAt ? timeAgo(processedByAiAt).toLocaleString() : null}</Td>
                        </Tr>
                    )}
                    <Tr>
                        <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>City</Td>
                        <Td>{city}</Td>
                    </Tr>
                    <Tr>
                        <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>State</Td>
                        <Td>{state}</Td>
                    </Tr>
                    <Tr>
                        <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>County</Td>
                        <Td>{county}</Td>
                    </Tr>
                    <Tr>
                        <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>Summary</Td>
                        <Td>{summary}</Td>
                    </Tr>
                    <Tr>
                        <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>Mandate Effects</Td>
                        <Td>
                            {mandateMandateEffects.map((mf, index) => (
                                <ol key={mf.description}>
                                    <li>
                                        <div className="flex flex-col mb-4">
                                            <p className="underline">
                                                {index + 1}.{" "}
                                                {
                                                    mandatesEffectQuery.data?.find((m) => m.id === mf.mandateEffectId)
                                                        ?.effectName
                                                }
                                            </p>
                                            <p>{mf.description}</p>
                                            <br />
                                        </div>
                                    </li>
                                </ol>
                            ))}
                        </Td>
                    </Tr>
                </TBody>
            </Table>
        </div>
    ) : (
        <p>No mandate found</p>
    );
}

export default MandatePage;
