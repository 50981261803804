import { useMutation, useQuery } from "@tanstack/react-query";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { getCompanyMandateAssignments } from "../../company-mandate-assignments/actions/get-company-mandate-assignments";
import { useAuth } from "../../auth/use-auth";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { useMemo, useState } from "react";
import { getMandates } from "../../mandates/actions/get-mandates";
import { getMandateEffects } from "../../mandate-effects/actions/get-mandate-effects";
import { orderBy, startCase } from "lodash";
import { getMandateMandateEffects } from "../../mandate-mandate-effects/actions/get-mandate-mandate-effects";
import { getIndustryGroups } from "../../industry-groups/actions/get-industry-groups";
import { format } from "date-fns";
import { assignMandates } from "../actions/assign-mandates";
import ButtonNeoGen from "../../layout/button-neogen";
import { Select } from "../../layout/form/select-input";
import { AutoAssignMandatesModal } from "../../company-mandate-assignments/components/auto-assign-mandates-modal";
import {
    UpdateCompanyMandateAssignment,
    updateCompanyMandateAssignment,
} from "../../company-mandate-assignments/actions/update-company-mandate-assignment";
import { Link } from "react-router-dom";
import { AddMandateAssignmentModal } from "../../company-mandate-assignments/components/add-mandate-assignment-modal";
import { useMandates } from "../../mandates/hooks/use-mandates";
import { useMandateMandateEffects } from "../../mandate-mandate-effects/hooks/use-mandate-mandate-effects";
import { useIndustryGroups } from "../../industry-groups/hooks/use-industry-groups";
import { useMandateEffects } from "../../mandate-effects/hooks/use-mandate-effects";
import { useCompanyMandateAssignments } from "../../company-mandate-assignments/hooks/use-company-mandate-assignments";
import { EllipseText, ResponsiveEllipsis } from "../../layout/text";

export const Mandates = ({ companyId, owner }: { companyId: number; owner: User }) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const [showAutoAssignModal, setShowAutoAssignModal] = useState(false);
    const [showAddMandateEffectModal, setShowAddMandateEffectModal] = useState(false);

    const companyMandateAssignmentsQuery = useCompanyMandateAssignments({
        authToken,
        filters: { where: { companyId } },
    });
    const companyMandateAssignments = useMemo(
        () => companyMandateAssignmentsQuery.data || [],
        [companyMandateAssignmentsQuery.data],
    );

    const mandateIds = companyMandateAssignments.map((assignment) => assignment.mandateId);
    const mandatesQuery = useMandates(
        { authToken, filters: { where: { id: { inq: mandateIds } } } },
        {
            enabled: companyMandateAssignmentsQuery.isSuccess && companyMandateAssignments.length > 0,
        },
    );
    const mandates = useMemo(() => mandatesQuery.data || [], [mandatesQuery.data]);

    const mandateEffectIds = companyMandateAssignments.map((a) => a.mandateEffectId);
    const mandateEffectsQuery = useMandateEffects(
        {
            authToken,
            filters: { where: { id: { inq: mandateEffectIds } } },
        },
        {
            enabled: companyMandateAssignmentsQuery.isSuccess && companyMandateAssignments.length > 0,
        },
    );
    const mandateEffects = useMemo(() => mandateEffectsQuery.data || [], [mandateEffectsQuery.data]);

    const mandateMandateEffectsQuery = useMandateMandateEffects(
        {
            authToken,
            filters: { where: { mandateId: { inq: mandateIds, mandateEffectId: { inq: mandateEffectIds } } } },
        },
        {
            enabled: companyMandateAssignmentsQuery.isSuccess && companyMandateAssignments.length > 0,
        },
    );
    const mandateMandateEffects = useMemo(
        () => mandateMandateEffectsQuery.data || [],
        [mandateMandateEffectsQuery.data],
    );

    const i = companyMandateAssignments.filter((a) => !!a.industryGroupId);

    const industryGroupIds = i.map((assignment) => assignment.industryGroupId);
    const industryGroupsQuery = useIndustryGroups(
        {
            authToken,
            filters: { where: { id: { inq: industryGroupIds } } },
        },
        {
            enabled: companyMandateAssignmentsQuery.isSuccess && i.length > 0,
        },
    );
    const industryGroups = useMemo(() => industryGroupsQuery.data || [], [industryGroupsQuery.data]);

    const rows = useMemo(
        () =>
            orderBy(
                companyMandateAssignments.map((cma) => {
                    return {
                        ...cma,
                        mandate: mandates.find((m) => m.id === cma.mandateId),
                        mandateEffect: mandateEffects.find((me) => me.id === cma.mandateEffectId),
                        mandateMandateEffect: mandateMandateEffects.find(
                            (me) => me.mandateId === cma.mandateId && me.mandateEffectId === cma.mandateEffectId,
                        ),
                        industryGroup: industryGroups.find((ig) => ig.id === cma.industryGroupId),
                    };
                }),
                (row) => row.mandate?.name,
                "desc",
            ),
        [companyMandateAssignments, industryGroups, mandateEffects, mandateMandateEffects, mandates],
    );

    const suggestedRows = useMemo(() => rows.filter((row) => row.status === "suggested"), [rows]);
    const acceptedRows = useMemo(() => rows.filter((row) => row.status === "accepted"), [rows]);
    const rejectedRows = useMemo(() => rows.filter((row) => row.status === "rejected"), [rows]);

    const updateCompanyMandateAssignmentMutation = useMutation({
        mutationKey: ["updateCompanyMandateAssignment", { authToken }],
        mutationFn: async ({ id, data }: { id: number; data: UpdateCompanyMandateAssignment }) => {
            const updatedMandateAssignment = await updateCompanyMandateAssignment({ authToken, id, data });
            return updatedMandateAssignment;
        },
        onSettled: () => companyMandateAssignmentsQuery.refetch(),
    });

    return (
        <>
            {showAutoAssignModal && (
                <AutoAssignMandatesModal
                    companyId={companyId}
                    onClose={() => setShowAutoAssignModal(false)}
                    onAssigned={() => {
                        companyMandateAssignmentsQuery.refetch();
                        setShowAutoAssignModal(false);
                    }}
                />
            )}
            {showAddMandateEffectModal && (
                <AddMandateAssignmentModal
                    companyId={companyId}
                    onClose={() => setShowAddMandateEffectModal(false)}
                    onCreated={() => {
                        setShowAddMandateEffectModal(false);
                        companyMandateAssignmentsQuery.refetch();
                    }}
                />
            )}
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 12 }}>
                <div style={{ fontSize: 24, fontWeight: 500 }}>Mandates Assigned To This Company</div>
                <div>
                    <ButtonNeoGen onClick={() => setShowAddMandateEffectModal(true)}>Add Mandate Effect</ButtonNeoGen>
                    <ButtonNeoGen
                        onClick={() => {
                            setShowAutoAssignModal(true);
                        }}
                    >
                        Auto-Assign Mandates
                    </ButtonNeoGen>
                </div>
            </div>
            <Table>
                <THead>
                    <Tr>
                        <Th style={{ width: 190 }}>Mandate</Th>
                        <Th style={{ width: 190 }}>Mandate Location</Th>
                        <Th>Effect</Th>
                        <Th style={{ width: 190 }}>Status</Th>
                    </Tr>
                </THead>
                <TBody>
                    {rows.length === 0 && (
                        <Tr>
                            <Td colSpan={5} style={{ textAlign: "center" }}>
                                No Mandate Effects
                            </Td>
                        </Tr>
                    )}
                    {suggestedRows.length > 0 && (
                        <>
                            <Tr>
                                <Td colSpan={4}>
                                    <b>Suggested Mandate Effects ({suggestedRows.length})</b>
                                </Td>
                            </Tr>
                            {suggestedRows.map((row) => (
                                <Tr key={row.id}>
                                    <Td style={{ verticalAlign: "top" }}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ fontWeight: 500 }}>
                                                <Link to={`/mandates/${row.mandate?.id}`}>{row.mandate?.name}</Link>
                                            </div>
                                            <div style={{ color: "gray", fontSize: 14 }}>
                                                {row.mandate?.date && format(row.mandate.date, "yyyy-MM-dd")}
                                            </div>
                                        </div>
                                    </Td>
                                    <Td style={{ verticalAlign: "top" }}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ color: "gray", fontSize: 14 }}>
                                                <div>State: {row.mandate?.state}</div>
                                                <div>County: {row.mandate?.county}</div>
                                                <div>City: {row.mandate?.city}</div>
                                            </div>
                                        </div>
                                    </Td>
                                    <Td style={{ verticalAlign: "top" }}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ fontWeight: 500 }}>
                                                {startCase(row.mandateEffect?.effectName || "")}{" "}
                                                {row.industryGroup ? `(${row.industryGroup.name})` : null}
                                            </div>
                                            <div style={{ fontSize: 13, color: "gray" }}>
                                                <ResponsiveEllipsis
                                                    text={row.mandateMandateEffect?.description || ""}
                                                    maxLine="2"
                                                    ellipsis="..."
                                                    trimRight
                                                    basedOn="letters"
                                                />
                                            </div>
                                        </div>
                                    </Td>
                                    <Td>
                                        <Select
                                            options={[
                                                { label: "Suggested", value: "suggested" },
                                                { label: "Accepted", value: "accepted" },
                                                { label: "Rejected", value: "rejected" },
                                            ]}
                                            value={row.status || null}
                                            onChange={(value: any) => {
                                                updateCompanyMandateAssignmentMutation.mutate({
                                                    id: row.id,
                                                    data: { status: value },
                                                });
                                            }}
                                        />
                                    </Td>
                                </Tr>
                            ))}
                        </>
                    )}
                    {acceptedRows.length > 0 && (
                        <>
                            <Tr>
                                <Td colSpan={4}>
                                    <b>Accepted Mandate Effects ({acceptedRows.length})</b>
                                </Td>
                            </Tr>
                            {acceptedRows.map((row) => (
                                <Tr key={row.id}>
                                    <Td style={{ verticalAlign: "top" }}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ fontWeight: 500 }}>
                                                <Link to={`/mandates/${row.mandate?.id}`}>{row.mandate?.name}</Link>
                                            </div>
                                            <div style={{ color: "gray", fontSize: 14 }}>
                                                {row.mandate?.date && format(row.mandate.date, "yyyy-MM-dd")}
                                            </div>
                                        </div>
                                    </Td>
                                    <Td style={{ verticalAlign: "top" }}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ color: "gray", fontSize: 14 }}>
                                                <div>State: {row.mandate?.state}</div>
                                                <div>County: {row.mandate?.county}</div>
                                                <div>City: {row.mandate?.city}</div>
                                            </div>
                                        </div>
                                    </Td>
                                    <Td style={{ verticalAlign: "top" }}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ fontWeight: 500 }}>
                                                {startCase(row.mandateEffect?.effectName || "")}{" "}
                                                {row.industryGroup ? `(${row.industryGroup.name})` : null}
                                            </div>
                                            <div style={{ fontSize: 13, color: "gray" }}>
                                                <ResponsiveEllipsis
                                                    text={row.mandateMandateEffect?.description || ""}
                                                    maxLine="2"
                                                    ellipsis="..."
                                                    trimRight
                                                    basedOn="letters"
                                                />
                                            </div>
                                        </div>
                                    </Td>
                                    <Td>
                                        <Select
                                            options={[
                                                { label: "Suggested", value: "suggested" },
                                                { label: "Accepted", value: "accepted" },
                                                { label: "Rejected", value: "rejected" },
                                            ]}
                                            value={row.status || null}
                                            onChange={(value: any) => {
                                                updateCompanyMandateAssignmentMutation.mutate({
                                                    id: row.id,
                                                    data: { status: value },
                                                });
                                            }}
                                        />
                                    </Td>
                                </Tr>
                            ))}
                        </>
                    )}
                    {rejectedRows.length > 0 && (
                        <>
                            <Tr>
                                <Td colSpan={4}>
                                    <b>Rejected Mandate Effects ({rejectedRows.length})</b>
                                </Td>
                            </Tr>
                            {rejectedRows.map((row) => (
                                <Tr key={row.id}>
                                    <Td style={{ verticalAlign: "top" }}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ fontWeight: 500 }}>
                                                <Link to={`/mandates/${row.mandate?.id}`}>{row.mandate?.name}</Link>
                                            </div>
                                            <div style={{ color: "gray", fontSize: 14 }}>
                                                {row.mandate?.date && format(row.mandate.date, "yyyy-MM-dd")}
                                            </div>
                                        </div>
                                    </Td>
                                    <Td style={{ verticalAlign: "top" }}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ color: "gray", fontSize: 14 }}>
                                                <div>State: {row.mandate?.state}</div>
                                                <div>County: {row.mandate?.county}</div>
                                                <div>City: {row.mandate?.city}</div>
                                            </div>
                                        </div>
                                    </Td>
                                    <Td style={{ verticalAlign: "top" }}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ fontWeight: 500 }}>
                                                {startCase(row.mandateEffect?.effectName || "")}{" "}
                                                {row.industryGroup ? `(${row.industryGroup.name})` : null}
                                            </div>
                                            <div style={{ fontSize: 13, color: "gray" }}>
                                                <ResponsiveEllipsis
                                                    text={row.mandateMandateEffect?.description || ""}
                                                    maxLine="2"
                                                    ellipsis="..."
                                                    trimRight
                                                    basedOn="letters"
                                                />
                                            </div>
                                        </div>
                                    </Td>
                                    <Td>
                                        <Select
                                            options={[
                                                { label: "Suggested", value: "suggested" },
                                                { label: "Accepted", value: "accepted" },
                                                { label: "Rejected", value: "rejected" },
                                            ]}
                                            value={row.status || null}
                                            onChange={(value: any) => {
                                                updateCompanyMandateAssignmentMutation.mutate({
                                                    id: row.id,
                                                    data: { status: value },
                                                });
                                            }}
                                        />
                                    </Td>
                                </Tr>
                            ))}
                        </>
                    )}
                </TBody>
            </Table>
        </>
    );
};
